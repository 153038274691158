import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { TrackerContext } from './Tracker';
import FooterLogo from './logos/coupang_partners_white.png';
import Kakao from './logos/kakao.png';
import Facebook from './logos/facebook.png';
import Youtube from './logos/youtube.png';
import Naver from './logos/naver.png';
import Instagram from './logos/instagram.png';

import './Footer.css';

export default function Footer() {
    const history = useHistory();
    const { trackEvent } = useContext(TrackerContext);
    const { affiliate } = useSelector((state) => state);
    const coupangCEO = useSelector((state) => state.config.coupangCEO);

    return (
        <div className="footer-container">
            <footer className="app-footer">
                <ul className="app-footer-content">
                    <li>
                        <div style={{ marginBottom: 15 }}>
                            <img src={FooterLogo} alt="coupang partners" width={90} />
                        </div>
                    </li>
                    <li>
                        <div>쿠팡(주) | 대표이사 : {coupangCEO || '김범석, 고명주, 박대준'}</div>
                        <div>서울시 송파구 송파대로 570 </div>
                    </li>
                    <li>
                        <div>사업자 등록번호 : 120-88-00767 </div>
                        <div>
                            통신판매업신고 : 2017-서울송파-0680 /{' '}
                            <a
                                href="http://www.ftc.go.kr/info/bizinfo/communicationViewPopup.jsp?wrkr_no=1208800767"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                사업자정보 확인
                            </a>
                        </div>
                        <a href="https://privacy.coupang.com/ko/center/coupang/" target="_blank">
                            <b>개인정보 처리방침</b>
                        </a>
                        <div>Copyright © Coupang Corp. 2018-2020 All Rights Reserved.</div>
                    </li>
                    <li>
                        <div>
                            문의하기{' '}
                            <a
                                onClick={() => {
                                    if (affiliate) {
                                        history.push('/help/voc/create');
                                    } else {
                                        window.location = 'mailto:partners@coupang.com';
                                    }
                                }}
                            >
                                partners@coupang.com​
                            </a>
                        </div>
                        <div className="support-channels">
                            <a
                                href="https://pf.kakao.com/_TTxlwj"
                                title="Kakao"
                                rel="noopener noreferrer"
                                target="_blank"
                                onClick={() => trackEvent({ action: 'communication_channel', value: 'kakao' })}
                            >
                                <img src={Kakao} alt="" />
                            </a>
                            <a
                                href="https://www.facebook.com/Partners.Coupang"
                                title="Facebook"
                                rel="noopener noreferrer"
                                target="_blank"
                                onClick={() => trackEvent({ action: 'communication_channel', value: 'facebook' })}
                            >
                                <img src={Facebook} alt="" />
                            </a>
                            <a
                                href="https://www.youtube.com/channel/UCwJBfcQVt4BtXgut384AV6w"
                                title="YouTube"
                                rel="noopener noreferrer"
                                target="_blank"
                                onClick={() => trackEvent({ action: 'communication_channel', value: 'youtube' })}
                            >
                                <img src={Youtube} alt="" />
                            </a>
                            <a
                                href="https://blog.naver.com/partners_official"
                                title="Naver"
                                rel="noopener noreferrer"
                                target="_blank"
                                onClick={() => trackEvent({ action: 'communication_channel', value: 'naver' })}
                            >
                                <img src={Naver} alt="" />
                            </a>
                            <a
                                href="https://www.instagram.com/coupangpartners_official"
                                title="Instagram"
                                rel="noopener noreferrer"
                                target="_blank"
                                onClick={() => trackEvent({ action: 'communication_channel', value: 'instagram' })}
                            >
                                <img src={Instagram} alt="" />
                            </a>
                        </div>
                    </li>
                </ul>
            </footer>
        </div>
    );
}
