import React, { useState, useRef, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { If, Then, Else } from 'react-if';
import MediaQuery from 'react-responsive';
import { NavMenu, UserProfileToolbar, LoginSignup, Notification } from '../components';
import MenuItems from './MenuItems';
import { Consts, TrackerContext, isAbroadAffiliate } from '../../common';
import { LocaleContext } from '../../components';
import './Header.css';

export default function Header({ logo }) {
    const intl = useIntl();
    const location = useLocation();
    const history = useHistory();
    const loggedIn = useSelector((state) => state.session.authenticated);
    const email = useSelector((state) => state.session.email);
    const simulated = useSelector((state) => state.session.simulated);
    const config = useSelector((state) => state.config);
    const { logoutUrl, loginUrl } = config;
    const affiliate = useSelector((state) => state.affiliate);
    const affiliateCode = affiliate && affiliate.affiliateCode;
    const activated = affiliate && affiliate.activated;
    const webAccessibleStatus = useSelector((state) => state.webAccessibleStatus);
    const { locale, setLocale } = useContext(LocaleContext);
    const { trackEvent } = useContext(TrackerContext);
    const routeMenuRef = useRef();

    const findMenuItem = (menuItem, path) => {
        if (menuItem && menuItem.key === 'home' && menuItem.route === path) {
            routeMenuRef.current = menuItem;
            return;
        }

        if (menuItem.route && menuItem.key !== 'home') {
            if (menuItem.route === path || new RegExp(`^${menuItem.route}`, 'i').test(path)) {
                routeMenuRef.current = menuItem;
                return;
            }
        }
        if (Array.isArray(menuItem.children) && menuItem.children.length) {
            for (let x of menuItem.children) {
                findMenuItem(x, path);
                if (routeMenuRef.current) break;
            }
        }
    };

    const findCorrespondingNavMenuByRoutePath = (location) => {
        routeMenuRef.current = null;
        if (location.pathname === '/') {
            return MenuItems[0].key;
        }

        for (let item of MenuItems) {
            findMenuItem(item, location.pathname);
            if (routeMenuRef.current) break;
        }
        if (routeMenuRef.current) {
            if (routeMenuRef.current.visible === false) {
                return routeMenuRef.current.parentKey;
            }
            return routeMenuRef.current.key;
        }
    };

    const getActiveMenu = (location) => {
        let menuItem = findCorrespondingNavMenuByRoutePath(location);
        // if (menuItem) {
        //     if (menuItem.visible === false) {
        //         return menuItem.parentKey;
        //     }
        //     return menuItem.key;
        // }
        return menuItem;
    };

    const [activeMenuItem, setActiveMenuItem] = useState(getActiveMenu(location));

    const rsLoginSignup = {
        Login: intl.formatMessage({ id: 'LOGIN' }),
        Signup: intl.formatMessage({ id: 'SIGNUP' }),
    };

    const getDropMenuItems = () => {
        const { regFinalStatus } = affiliate || {};
        const dropMenus = [];

        if (affiliate && !webAccessibleStatus.blocked) {
            dropMenus.push({
                key: 'editprofile',
                title: intl.formatMessage({ id: 'EDIT_PROFILE_TITLE' }),
            });
        }

        if (affiliate && !isAbroadAffiliate(affiliate) && !webAccessibleStatus.blocked) {
            dropMenus.push({
                key: 'editpayment',
                title: intl.formatMessage({ id: 'EDIT_PAYMENT_TITLE' }),
            });
        }

        if (affiliate && !webAccessibleStatus.blocked) {
            dropMenus.push({
                key: 'opt-in-out-settings',
                title: intl.formatMessage({ id: 'OPT_IN_OUT_SETTINGS' }),
            });
        }

        if (affiliate && !isAbroadAffiliate(affiliate) && !webAccessibleStatus.blocked) {
            dropMenus.push({
                key: 'subids',
                title: intl.formatMessage({ id: 'HEADER_SUB_ID' }),
            });
        }

        // if (dropMenus.length) {
        //     dropMenus.push({ divider: true });
        // }
        // dropMenus.push({ key: 'logout', title: intl.formatMessage({ id: 'LOGOUT' }) });

        return dropMenus;
    };

    const translateMenu = (menuItem) => {
        if (menuItem.titleKey) {
            menuItem.title = intl.formatMessage({ id: menuItem.titleKey });
        }
        if (Array.isArray(menuItem.children) && menuItem.children.length) {
            menuItem.children.forEach((y) => {
                translateMenu(y);
            });
        }
    };

    const translateResource = () => {
        MenuItems.forEach((x) => {
            translateMenu(x);
        });
    };

    const onLogin = () => {
        trackEvent({ action: 'login' });

        window.sessionStorage.entrance = Consts.Entrance.Login;

        if (loggedIn && (!affiliate || !affiliate.affiliateCode)) {
            history.push('/reg');
        } else {
            setTimeout(() => {
                window.location.href = loginUrl;
            }, 150);
        }
    };

    const onSignup = () => {
        trackEvent({ action: 'signup' });

        window.sessionStorage.entrance = Consts.Entrance.Signup;

        if (loggedIn && (!affiliate || !affiliate.affiliateCode)) {
            history.push('/reg');
        } else {
            history.push('/gotologin');
        }
    };

    const logout = () => {
        window.location.href = logoutUrl;
    };

    const onNavMenuClicked = (e) => {
        setActiveMenuItem(e.key);
    };

    // hasMyAccount

    const onDropMenuClicked = (e) => {
        switch (e.key) {
            case 'my-account':
                history.push('/affiliate/myaccount');
                break;
            case 'logout':
                logout();
                break;
            case 'editprofile':
                history.push('/affiliate/profile');
                break;
            case 'editpayment':
                history.push('/affiliate/payment');
                break;
            case 'opt-in-out-settings':
                history.push('/affiliate/opt-in-out-settings');
                break;
            case 'subids':
                history.push('/affiliate/sub-ids');
                break;
            case 'unsubscribe':
                history.push('/affiliate/unsubscribe');
                break;
            default:
                break;
        }
    };

    const filterMenuItems = (items, result, parentLoginRequired) => {
        for (let item of items) {
            let include = loggedIn;
            let mi = { ...item, children: [] };

            if ((item.loginRequired === false || parentLoginRequired === false) && !loggedIn) {
                include = true;
            }

            if (item.condition && typeof item.condition === 'function') {
                include = item.condition(affiliate, config);
            }
            if (include) {
                result.push(mi);
                if (Array.isArray(item.children)) {
                    filterMenuItems(
                        item.children,
                        mi.children,
                        !(parentLoginRequired === false || item.loginRequired === false)
                    );
                }
            }
        }
    };

    const onBrandClicked = (e) => {
        if (e.shiftKey) {
            e.preventDefault();
            if (locale === 'ko') {
                window.cpapp.lang = 'en';
                setLocale('en');
            } else {
                window.cpapp.lang = 'ko';
                setLocale('ko');
            }
        }
    };

    // Display Nav Menu only when current route matches any menu item
    const displayNavMenu = findCorrespondingNavMenuByRoutePath(location) || location.pathname === '/affiliate/sub-ids';

    let filteredMenuItems = [];
    translateResource();
    filterMenuItems(MenuItems, filteredMenuItems);
    // const helpMenu = filteredMenuItems.find(x => x.key === 'help');

    let dropMenuItems = getDropMenuItems();

    const trackingCode = affiliateCode;

    useEffect(() => {
        history.listen((location) => {
            setActiveMenuItem(getActiveMenu(location));
        });
        // eslint-disable-next-line
    }, []);

    return (
        <header className="app-header" id="app-header">
            <div className="header-brand">
                <Link to="/">
                    <img src={logo.image} alt={logo.text} width={70} onClick={onBrandClicked} />
                </Link>
            </div>
            <MediaQuery minWidth={992}>
                {(matches) => {
                    const mode = matches ? 'PC' : 'MOBILE';
                    // on mobile, display always; on pc, display the menu only if user logs in
                    if (displayNavMenu) {
                        return (
                            <NavMenu
                                mode={mode}
                                items={filteredMenuItems}
                                activeMenuItem={activeMenuItem}
                                onClick={onNavMenuClicked}
                            />
                        );
                    }
                    return null;
                }}
            </MediaQuery>

            <div className="header-toolbar">
                {
                    <If condition={!!loggedIn && (!!affiliate || location.pathname !== '/')}>
                        <Then>
                            {affiliate && !webAccessibleStatus.blocked && <Notification />}
                            <UserProfileToolbar
                                id={trackingCode}
                                title={email}
                                affiliate={affiliate}
                                activated={activated}
                                simulated={simulated}
                                webAccessibleStatus={webAccessibleStatus}
                                menuItems={dropMenuItems}
                                onMenuClick={onDropMenuClicked}
                            />
                        </Then>
                        <Else>
                            <LoginSignup
                                items={filteredMenuItems}
                                onLogin={onLogin}
                                onSignup={onSignup}
                                rs={rsLoginSignup}
                            ></LoginSignup>
                        </Else>
                    </If>
                }
            </div>
        </header>
    );
}
